import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import { format, parseISO, isThisYear } from 'date-fns';

import { contentWidth, media } from './theme';

const PostsList = ({ edges }) => {
  return (
    <StyledPostsList className="posts-list">
      {edges.map(({ node }) => {
        const { mainImage, slug, title, excerpt, publishedAt } = node;
        const publishDate = parseISO(publishedAt);
        return (
          <PostItem className="post-item">
            <Link to={`/post/${slug.current}`} className="post-item__link">
              {mainImage && (
                <Image
                className="post-item__image"
                fluid={mainImage.asset.fluid}
                />
              )}
              <h2 className="post-item__title">{title}</h2>
              <span className="post-item__date">{isThisYear(publishDate) ? format(publishDate, 'MMMM d') : format(publishDate, 'MMMM d, yyyy')}</span>
              <div className="post-item__excerpt">{excerpt}</div>
            </Link>
          </PostItem>
        );
      })}
    </StyledPostsList>
  );
};

const StyledPostsList = styled.ul`
  list-style: none;
  padding: 1rem;
  margin: 5rem 0;
  ${contentWidth}
  width: 900px;
`;

const PostItem = styled.li`
  margin-bottom: 3rem;
  /* padding-bottom: 3rem; */
  ::after {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    content: '';
    height: 2px;
    margin-top: 3rem;
    background: ${({ theme }) => theme.darkOrange};
  }
  .post-item {
    &__link {
      display: block;
      color: inherit;
      text-decoration: none;
    }
    &__image {
      width: 400px;
      max-width: 100%;
      margin-bottom: 1rem;
    }
    &__date {
      margin-bottom: 1rem;
      display: block;
      font-family: ${({ theme }) => theme.font.boldFamily};
      color: ${({ theme }) => theme.darkOrange};
    }
  }

  ${({ theme }) => theme.grid && media.break`
    .post-item {
      &__link {
        display: grid;
        grid-template-columns: minmax(100px, 400px) 2fr;
        grid-template-rows: auto auto auto;
        grid-gap: 1rem 2rem;
        align-items: center;
      }
      &__image {
        grid-row: 1 / 4;
        grid-column: 1 / 2;
      }
      &__title {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        align-self: end;
      }
      &__date {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
      }
      &__excerpt {
        grid-row: 3 / 4;
        grid-column: 2 / 3;
        align-self: start;
      }
    }
  `}
`;

export default PostsList;
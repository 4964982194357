import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Global/Wrapper';
import PostsList from '../components/PostsList';
import Pagination from '../components/Pagination';

const news = ({ data, pageContext }) => {
  return (
    <Wrapper title="News">
      <h1>News</h1>
      <PostsList {...data.allSanityPost} />
      <Pagination {...pageContext} slug="news" />
    </Wrapper>
  );
};

export const PostsQuery = graphql`
  query postsQuery($skip: Int!, $limit: Int!) {
    allSanityPost(
      skip: $skip
      limit: $limit
      sort: {
        order: DESC,
        fields: publishedAt
      }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          excerpt
          publishedAt
          mainImage {
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default news;